import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import Class from "better-classnames";

const Layout = ({ children, classNames, header }) => {
  return (
    <div className="w-full h-full flex flex-col content-background">
      <header className="flex flex-row">
        <h1 className="mx-auto py-2 text-xl md:text-3xl font-bold">{header}</h1>
      </header>
      <main className="flex-grow">
        <div className={Class(classNames, "py-5")}>{children}</div>
      </main>
      <footer className="text-center flex flex-row py-2">
        <a className="text-xs text-gray-800 mx-auto" href="/">
          © Natmegy | {Moment().format("yyyy")}
        </a>
      </footer>
    </div>
  );
};

Layout.defaultProps = {
  classNames: "",
};

Layout.propTypes = {
  classNames: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
